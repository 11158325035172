import React from "react";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import "../scss/main.scss";
import Footer from "../components/Footer";
import WhatsappButton from "../components/WhatsappButton";

import { Link } from "gatsby";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import 'bootstrap/dist/css/bootstrap.min.css';

/* import { MARKS } from "@contentful/rich-text-types"; */
/* import SyntaxHighlighter from "react-syntax-highlighter";
import { monokai } from "react-syntax-highlighter/dist/esm/styles/hljs"; */
// import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
/* import Disqus from 'disqus-react' */
export const query = graphql`
  query ($slug: String!) {
    contentfulResourcePost(slug: { eq: $slug }) {
      title
      subtitle {
        subtitle
      }
      image {
        file {
          url
        }
        gatsbyImageData
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 700)
            __typename
          }
        }
      }
      slug
    }
  }
`;

const Blog = (props) => {
  const pageTitle = props.data.contentfulResourcePost.title + " | Virtual Ed Global";
  const pageDescription = props.data.contentfulResourcePost.subtitle.subtitle;

  const Bold = ({ children }) => <strong>{children}</strong>;
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    /* To render <br> */
    renderText: (text) => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <>
            <h2>Embedded Asset</h2>
            <pre>
              <code>{JSON.stringify(node, null, 2)}</code>
            </pre>
          </>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target;
        return <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />;
      },
    },
  };

  return (
    <main className="ligh-color">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content={`https://virtualedglobal.com/blog/${props.data.contentfulResourcePost.slug}`} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={props.data.contentfulResourcePost.image.file.url} />
        <meta name="twitter:image" content={props.data.contentfulResourcePost.image.file.url} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} /> 
      </Helmet>

      <Navbar />
      <div className="container">
        <section className="blog-entry">
          <div className="blog-entry-info">
            <div className="row align-items-center">
              <div className="col-md-5 col-lg-3">
                <div
                  className="img-container"
                  style={{ backgroundImage: "url(" + props.data.contentfulResourcePost.image.file.url + ")" }}
                ></div>
              </div>
              <div className="col-md-7 col-lg-9 texts-container">
                <div className="cat-name">blog post</div>
                <h1>{props.data.contentfulResourcePost.title}</h1>
                <p>{props.data.contentfulResourcePost.subtitle.subtitle}</p>
              </div>
            </div>
          </div>

          <div className="blog-entry-content">
            <div className="row">
              <div className="col-12">
                {renderRichText(props.data.contentfulResourcePost.body, options)}
                <Link to="/recursos" className="btn btn-dark">
                  Volver
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default Blog;
